/**
 * operationLog
 * 操作日志
 */

import request from '@/utils/request'

// 展示
export const operationLogSelect = (data) => {
    return request({
        method: 'post',
        url: '/operationLog/select',
        data
    })
}
