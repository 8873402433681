<template>
  <el-card id="log">
    <header>
      <h1>系统操作日志</h1>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <span style="width: 50px">操作人</span>
            <el-input
              v-model="userName"
              placeholder="请输入操作人"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span style="width: 120px">操作详细信息</span>
            <el-input
              v-model="remarke"
              placeholder="请输入操作详细信息"
              size="mini"
            ></el-input>
          </div>
          <div class="left-wrap">
            <span>操作时间</span>
            <el-date-picker
              v-model="createDate"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="center"
              size="mini"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="handleSearch"
            size="mini"
            class="searchBtn"
            >查找</el-button
          >
          <el-button
            class="reset"
            type="primary"
            icon="el-icon-refresh"
            @click="reset()"
            size="mini"
            >重置</el-button
          >
        </div>
      </div>
    </header>
    <hr>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
      >
        <!-- <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="userName"
          label="操作人"
          align="center"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="createDate"
          label="操作时间"
          align="center"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="executeTimeMills"
          label="耗时时间"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="remarke"
          label="操作详细信息"
          align="center"
        ></el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes,jumper"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>
  </el-card>
</template>

<script>
import { operationLogSelect } from "@/api/operationLog";
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      size: 10,
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      userName: null,
      startTime: null,
      endTime: null,
      remarke: null,
      createDate: null,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 搜索
    handleSearch() {
      this.getList();
    },
    // 重置
    reset() {
      this.createDate = null;
      this.userName = null;
      this.startTime = null;
      this.endTime = null;
      this.remarke = null;
      this.getList();
    },
    // 转换日期
    getYMDHMS(format, date) {
      if (!date) {
        return "";
      }
      date = new Date(date);
      const dataItem = {
        "Y+": date.getFullYear().toString(),
        "m+": (date.getMonth() + 1).toString(),
        "d+": date.getDate().toString(),
        "H+": date.getHours().toString(),
        "M+": date.getMinutes().toString(),
        "S+": date.getSeconds().toString(),
      };
      Object.keys(dataItem).forEach((item) => {
        const ret = new RegExp(`(${item})`).exec(format);
        if (ret) {
          format = format.replace(
            ret[1],
            ret[1].length === 1
              ? dataItem[item]
              : dataItem[item].padStart(ret[1].length, "0")
          );
        }
      });
      return format;
    },
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 获取数据
    getList() {
      if (this.createDate) {
        this.startTime = this.getYMDHMS(
          "YYYY-mm-dd HH:MM:SS",
          this.createDate[0]
        );
        this.endTime = this.getYMDHMS(
          "YYYY-mm-dd HH:MM:SS",
          this.createDate[1]
        );
      }
      let params = {
        page: this.page,
        size: this.size,
        createUserName: this.userName,
        startTime: this.startTime,
        endTime: this.endTime,
        remarke: this.remarke,
      };
      console.log(123, params);
      let res = operationLogSelect(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
#log {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          span {
            margin: 10px;
            // width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>